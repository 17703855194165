import React from 'react'
import styled from 'styled-components'
import Color from '../../utils/color'
import { HeaderMed } from '../../components/Text'

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const NotFoundText = styled(HeaderMed)`
  color: ${Color.MudGrey};
`

function NotFound() {
  return (
    <div style={styles.container}>
      <NotFoundText>Sorry, Page Not Found</NotFoundText>
    </div>
  )
}

export default NotFound
