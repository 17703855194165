import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Icon from '../Icon'
import { HeaderMed } from '../Text'

const ContainerStyle = styled.div`
  transition: all 0.3s ease-out;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme, drawBehind }) => drawBehind ? '#fff' : theme.color.text};
  background: ${({ drawBehind }) => drawBehind ? 'transparent' : '#fff'};
  ${props => props.drawBehind && 'box-shadow: rgba(0, 0, 0, 0.1)'};
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
`

const BackButton = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`


const titleMap = {
  checkout: <FormattedMessage id="components.Header.Title.checkout" />,
  'card-pay': <FormattedMessage id="components.Header.Title.cardPay" />,
}

// @TODO: only for location.host === 'fbb.pickpack.de ?
const pathsHidingBackButton = [/\/store\/*/]

function Header({
  location: { pathname },
  history,
  children,
  title,
  goBack,
  drawBehind,
  setRef,
}) {
  function onClickBackButton() {
    return goBack ? goBack() : history.goBack()
  }

  const simpleHeaderTitle = title || titleMap[pathname.split('/')[1]]

  const backButtonVisible = !pathsHidingBackButton.some(path => path.test(pathname))

  return (
    <ContainerStyle drawBehind={drawBehind} ref={setRef}>
      <BackButton onClick={onClickBackButton}>
        {backButtonVisible && <Icon name="arrow_left" />}
      </BackButton>
      <HeaderMed>
        {children || simpleHeaderTitle}
      </HeaderMed>
      {/* pseudo spacing to apply justify-content: space-between */}
      <BackButton />
    </ContainerStyle>
  )
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  drawBehind: PropTypes.bool,
  goBack: PropTypes.func,
  setRef: PropTypes.any,
}

Header.defaultProps = {
  children: null,
  title: '',
  drawBehind: false,
  goBack: null,
  setRef: () => {},
}

export default withRouter(Header)
