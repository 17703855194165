import { useEffect, useContext } from 'react'
import moment from 'moment'
import { getCurrentAvailablePickupTime } from '../utils/time'
import { SessionContext } from '../providers/SessionContextProvider'

let updater
function usePickupTimer({ store }) {
  const { pickup, writeCache } = useContext(SessionContext)

  useEffect(() => {
    if (updater) clearInterval(updater)
    updater = setInterval(checkTime, 1000)
    return () => clearInterval(updater)
  }, [store, pickup])

  function checkTime() {
    const newTime = getCurrentAvailablePickupTime(store, pickup)
    if (newTime && moment(pickup.time).format('HH:mm') !== newTime.format('HH:mm')) {
      writeCache({ pickup: { ...pickup, time: newTime.toISOString() } })
    }
  }
}

export default usePickupTimer
