import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Theme } from './theme'

export const ThemeContext = createContext()

// theme options
// 'default' : pick_pack style guide
// 'airport' : airport-app style guide

function ThemeContextProvider({ children, initialTheme }) {
  const [theme, setTheme] = useState(Theme[initialTheme] || Theme.default)

  useEffect(() => {
    if (window.location.hostname === 'fbb.pickpack.de') {
      setTheme(Theme.airport)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  )
}

ThemeContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
  initialTheme: PropTypes.string,
}

ThemeContextProvider.defaultProps = {
  initialTheme: 'default',
}

export default ThemeContextProvider
