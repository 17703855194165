import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Header from '../../components/Header'
import zIndex from '../../utils/zIndex'

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const HeaderArea = styled.div`
  position: ${props => (props.drawBehind ? 'absolute' : 'fixed')};
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${zIndex.header};
`

const Main = styled.div`
  flex: 1;
  padding-top: ${props => `${props.paddingTop}px`};
  padding-top: ${props => `${props.paddingTop}px`};
  padding-bottom: ${props => `${props.paddingBottom}px`};
  padding-bottom: ${props => `${props.paddingBottom}px`};
`

const FooterArea = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  ${props => props.hasFooter && `
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  `};
  background: #fff;
`

export const Footer = styled.div`
  padding: 1rem;
  background: #fff;
  display: flex;
  box-shadow: 0 0 4px 2px rgba(0,0,0,0.05);
`

let lastUsedRef = null
let lastFooterRef = null

function PageLayout({
  children, header, footer, noPaddingTop,
}) {
  const footerRef = useRef()
  const headerRef = useRef()
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)

  useEffect(() => {
    // do a trigger scroll update when the component is remounted again
    if (lastUsedRef !== null && lastUsedRef !== headerRef.current) {
      if (headerRef.current && headerHeight !== headerRef.current.offsetHeight) {
        setHeaderHeight(headerRef.current.offsetHeight)
      }
    }
    if (lastFooterRef !== null && lastFooterRef !== headerRef.current) {
      if (footerRef.current && footerHeight !== footerRef.current.offsetHeight) {
        setFooterHeight(footerRef.current.offsetHeight)
      }
    }
    lastUsedRef = headerRef.current
    lastFooterRef = footerRef.current
  })

  useEffect(() => {
    if (headerRef.current) setHeaderHeight(headerRef.current.offsetHeight)
    if (footerRef.current) setFooterHeight(footerRef.current.offsetHeight)
  }, [headerRef.current, footerRef.current])

  return (
    <Page>
      <HeaderArea ref={headerRef}>
        {header || <Header />}
      </HeaderArea>
      <Main paddingTop={noPaddingTop ? 0 : headerHeight} paddingBottom={footerHeight}>
        {children}
      </Main>
      <FooterArea ref={footerRef} hasFooter={!!footer}>
        {footer}
      </FooterArea>
    </Page>
  )
}

PageLayout.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any,
  footer: PropTypes.any,
  noPaddingTop: PropTypes.bool,
}

PageLayout.defaultProps = {
  children: null,
  header: null,
  footer: null,
  noPaddingTop: false,
}

export default PageLayout
