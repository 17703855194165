
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { searchUsers } from '../../graphql/queries'
import TextInput from '../../components/TextInput'
import LoadingSpinner from '../../components/LoadingSpinner'
import {
  SearchBox, ListContainer, Name, ItemContainer,
} from './styled'
import useImperativeQuery from '../../hooks/useImperativeQuery'
import Button from '../../components/Button'

function AdminUserList() {
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const runQuery = useImperativeQuery(searchUsers, { skip: true })

  async function triggerUserSearch() {
    setLoading(true)
    const { data } = await runQuery({ search_term: searchText })
    setLoading(false)
    if (data && data.searchUsers) setUsers(data.searchUsers)
  }

  function handleKeyPress({ key }) {
    if (key === 'Enter' && !loading) {
      triggerUserSearch()
    }
  }

  function UserRow(user) {

    const hasName = user.name && user.name !== user.email
    return (
      <Link to={`/admin/user/${encodeURIComponent(user.id)}`}>
        <ItemContainer key={user.id}>
          <Name>{hasName ? `${user.name} (${user.email})` : user.email}</Name>
          <Name>{user.id}</Name>
        </ItemContainer>
      </Link>
    )
  }

  return (
    <ListContainer>
      <SearchBox>
        <TextInput
          placeholder="Type user's name or email, and press Enter or Search button"
          value={searchText}
          onKeyPress={handleKeyPress}
          onChangeText={setSearchText}
        />
        <Button onClick={triggerUserSearch}>Search</Button>
      </SearchBox>
      {loading && <LoadingSpinner />}
      {users.map(UserRow)}
    </ListContainer>
  )
}

export default AdminUserList
