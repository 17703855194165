/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Color from '../../utils/color'

const textStyle = css`
  border-bottom: 1px solid
    ${({ error }) => (error ? Color.Yellow : Color.CoolGrey)};
  min-width: 0;
  width: 100%;
  font-size: 1rem;
  color: ${({ error, theme }) => (error ? Color.Yellow : theme.color.text)};
  caret-color: ${({ error, theme }) => (error ? Color.Yellow : theme.color.primary)};
  background: transparent;
  &:active,
  &:focus {
    border-color: ${({ error, theme }) => (error ? Color.Yellow : theme.color.primary)};
    & + span {
      transform: translateY(-100%);
      top: 0;
      font-size: 0.7rem;
      color: ${({ error, theme }) => (error ? Color.Yellow : theme.color.primary)};
    }
  }
  &:not(:focus) {
    & + span {
      font-size: 0.7rem;
      top: 0;
      ${props => props.hasText && 'transform: translateY(-100%)'};
      color: ${({ error }) => (error ? Color.Yellow : Color.Grey)};
    }
  }
`

const BaseTextarea = styled.textarea`
  border: ${Color.CoolGrey} solid 1px;
  ${textStyle}
  padding: 5px;
  width: 100%;
`

const BaseTextInput = styled.input`
  ${textStyle}
`

const Label = styled.div`
  position: relative;
  opacity: ${props => props.disabled ? 0.3 : 1.0};
`

const Placeholder = styled.span`
  position: ${props => props.stickToTop ? 'inherited' : 'absolute'};
  bottom: 0.3rem;
  left: 0;
  transition: 0.2s ease;
  color: ${Color.Grey};
  font-size: 0.8rem;
  white-space: pre;
`

function TextInput({
  required, placeholder, value, error, onChange, onChangeText, multiline, ...rest
}) {
  const inputRef = useRef()

  let placeholderText = error || placeholder
  if (typeof error === 'boolean') {
    placeholderText = placeholder
  }

  function handleChange(e) {
    onChange(e)
    onChangeText(e.target.value)
  }

  function focus() {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const InputElement = multiline ? BaseTextarea : BaseTextInput

  function handleBlur(e) {
    if (rest.onBlur) {
      rest.onBlur(e)
    }
    window.scrollBy(0, 0)
  }

  return (
    <Label {...rest}>
      {multiline && (
        <Placeholder onClick={focus} stickToTop>
          {placeholderText}
          {required && ' *'}
        </Placeholder>
      )}
      <InputElement
        type="text"
        {...rest}
        ref={inputRef}
        error={error}
        multiline
        placeholder=""
        hasText={!!value || value === 0}
        value={value || ''}
        required={required}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {!multiline && (
        <Placeholder onClick={focus}>
          {placeholderText}
          {required && ' *'}
        </Placeholder>
      )}
    </Label>
  )
}

TextInput.propTypes = {
  required: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool]),
  multiline: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
}

TextInput.defaultProps = {
  required: false,
  multiline: false,
  placeholder: '',
  value: '',
  error: null,
  onChange: () => {},
  onChangeText: () => {},
}

export default TextInput
