import { useState, useEffect } from 'react'
require('intersection-observer')

function useIntersection(ref, { initialValue = false, threshold = 0, options = {} }) {
  const [intersecting, setIntersecting] = useState(initialValue)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio >= threshold) {
        setIntersecting(true)
      } else {
        setIntersecting(false)
      }
    }, options)

    if (ref && ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref && ref.current) {
        observer.unobserve(ref.current)
      }
    }
  })

  return intersecting
}

export default useIntersection
