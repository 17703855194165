import gql from 'graphql-tag'

import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'

export const adminCampaignFragment = gql`
  fragment AdminCampaignFragment on Campaign {
    id
    brand_id
    store_id
    product {
      id
      name
    }
    product_id
    brand_name
    store_street
    store_name
    single_use_only
    price
    offer_name
    offer_partner_label
    quantity_max
    promotion_code
    type
    discount_type
    active_hours {
      day
      time
    }
    start_date
    end_date
    teaser_image_start
    teaser_image_detail
    is_featured
    terms
    discount_percent
    discount_amount
    user_quantity
    code_single_use
    show_success
    referral_name
    payed_by_partner_share  
    min_order_value
    block_activation_from
  }
`

export const userFragment = gql`
  fragment UserFragment on User {
    id
    name
    given_name
    family_name
    fraud_check
    email
    # email_verified
    nickname
    role
    phone_number
    # phone_number_verified
    # used_brand_ids
    # favorite_stores
    active_campaigns {
        id
        quantity_max
        promotion_code
        start_date
        end_date
        terms
        discount_percent
        user_quantity
        offer_name
        referral_name
    }
    # payment_details {
    #   paypal_id
    #   stripe_id
    #   default_source {
    #     id
    #     brand
    #     last4
    #     object
    #     fingerprint
    #     name
    #     type
    #   }
    #   sources {
    #     id
    #     brand
    #     last4
    #     object
    #     fingerprint
    #     name
    #     type
    #   }
    # }
    # accepted_agb
    # accept_agb
    # accept_privacy
    # accept_push
    # push_id
    # accept_newsletter
    # picture
    # referral_code
    # success_campaign {
    #   id
    #   offer_name
    #   referral_name
    #   show_success
    # }
  }
`

export const categoryFragment = gql`
  fragment CategoryFragment on Category {
      id
      name
      icon
      icon_cyan
      placeholder
      products {
        ...ProductFragment
      }
  }
`

export const variantProductFragment = gql`
  fragment VariantProductFragment on Product {
    id
    name
    price
    has_cutlery
    old_price
    is_promo
    has_inherited_extras
    image
    teaser_text
    teaser_text_long
    vat_rate
    preferred_height
    allergens
    title
  }
`

export const productFragment = gql`
  fragment ProductFragment on ProductInterface {
    id
    name
    price
    has_cutlery
    old_price
    is_promo
    image
    has_inherited_extras
    placeholder
    teaser_text
    teaser_text_long
    vat_rate
    # preferred_height
    allergens
    # specialities {
    #   food_type
    #   is_halal
    #   is_kosher
    # }
    # brand {
    #   id
    #   name
    #   short_name
    #   address
    #   contact
    #   logo
    #   allergens_info
    #   allergens_info_url
    # }
    category {
      id
      name
      icon
      placeholder
    }
    variants {
      variant_id
      name
      title
      price
      old_price
      is_promo
      has_inherited_extras
      allergens
      image
      is_default
      extra_groups {
        name
        extra_group_id
        # has_inputfield
        is_open
        default_extras
        minimum_extras
        maximum_extras
        items{
          extra_id
          group_id
          name
          price
          # specialities {
          #   food_type
          #   is_kosher
          #   is_halal
          # }
          allergens
          is_selected
        }
      }
      teaser_text
    }
    extra_groups {
      name
      extra_group_id
      # has_inputfield
      is_open
      default_extras
      minimum_extras
      maximum_extras
      items{
        extra_id
        group_id
        name
        price
        # specialities {
        #   food_type
        #   is_kosher
        #   is_halal
        # }
        allergens
        is_selected
      }
    }
  }
`

export const storeFragmentWithCategories = gql`
  fragment StoreFragmentWithCategories on Store {
    id
    # geo_hash
    banner
    # eat_in_option
    online
    published
    name
    # phone_number
    # website
    # description
    location_name
    # route_help
    # pickup_route_help
    # poi_location
    # product_tags
    # pickup_point {
    #   image
    #   max_height
    #   scale
    # }
    min_order_time # in seconds
    cancelation_time # in seconds
    # labels {
    #   text
    #   color
    # }
    # floor_plan
    # heartbeat
    # pickie
    block_campaigns
    opening_hours {
      day
      time
    }
    # address {
    #   city
    #   streetname
    #   streetno
    #   zip
    # }
      categories {
        id
        name
      }  
    brand {
      id
      name
      short_name
      # address
      logo
    }
  }
`
export const storeFragment = gql`
  fragment StoreFragment on Store {
    id
    # geo_hash
    banner
    # eat_in_option
    online
    published
    name
    # phone_number
    # website
    # description
    location_name
    # route_help
    # pickup_route_help
    # poi_location
    # product_tags
    # pickup_point {
    #   image
    #   max_height
    #   scale
    # }
    min_order_time # in seconds
    cancelation_time # in seconds
    # labels {
    #   text
    #   color
    # }
    # floor_plan
    # heartbeat
    # pickie
    block_campaigns
    opening_hours {
      day
      time
    }
    # address {
    #   city
    #   streetname
    #   streetno
    #   zip
    # }
    brand {
      id
      name
      short_name
      # address
      logo
    }
  }
`

export const storeCardFragment = gql`
  fragment StoreCardFragment on Store {
    id
    # geo_hash
    banner
    online
    # published
    name
    location_name
    product_tags
    opening_hours {
      day
      time
    }
    brand {
      id
      logo
      name
    }
    labels {
      text
      color
    }
  }
`

export const campaignFragment = gql`
  fragment CampaignFragment on Campaign {
    id
    price
    offer_name
    promotion_code
    discount_percent
    referral_name
    terms
  }
`

export const orderFragment = gql`
  fragment OrderFragment on Order {
    id
    transaction_id
    code_text
    readable_id
    created_at
    pickup_time
    pickedup_time
    type
    item_count
    platform_fee
    transaction_id
    payment_method
    status
    total
    store_total
    terminal_total
    cutlery
    eat_in
    store {
      ...StoreFragment
    }
    items{
      id
      name
      price
      base_price
      quantity
      product_id
      variant_id
      store_id
      charged_price_total
      tags
      image
      in_stock
      stock_quantity
      extras {
        extra_id
        name
        group_name
        price
        in_stock
      }
    }
    vat_total {
      vat_rate
      vat_amount
    }
    campaign {
      ...CampaignFragment
    }
  }
  ${storeFragment}
  ${campaignFragment}
`

export const locationFragment = gql`
  fragment LocationFragment on Location {
    id
    name
    tags
    label
    floor_plan
    lonlat {
      longitude
      latitude
    }
    address {
      city
      streetname
      streetno
      zip
    }
  }
`

export const basketFragment = gql`
  fragment BasketFragment on Basket {
    id
    user_id
    store_id
    total
    item_total
    state
    order_id
    platform_fee
    campaign {
      ...CampaignFragment
    }
    no_fee_campaign {
      ...CampaignFragment
    }
    items {
      id
      product_id
      name
      price
      image
      quantity
      teaser_text
      comment
      variant_id
      is_promo
      extras {
        extra_id
        name
        price
      }
    }
  }
  ${campaignFragment}
`


export const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'ProductInterface',
          possibleTypes: [
            { name: 'Product' },
            { name: 'FeaturedProduct2' },
          ],
        },
      ],
    },
  },
})
