export const Color = {
  Yellow: '#FFB900',
  White: '#FFFFFF',
  Berry: '#A32756',
  Cyan: '#00C9B7',
  Petrol: '#005f6A',
  DarkGreen: '#1c4448',
  LightGrey: '#F6F8F8',
  CoolGrey: '#dce2e3',
  Grey: '#8da1a3',
  MudGrey: '#9D9D9D',
  GreyBrown: '#484848',
  DarkGrey: '#555555',
  Black: '#000000',
  CloudyBlue: '#b9d1d3',
  DarkSkyBlue: '#2188E5',
  DangerRed: '#e8492e',
  DangerRedBackground: 'rgba(232,29,17,0.64)',
}

export const AirportAppColor = {
  Red: 'rgb(217, 47, 42)',
  Turquoise: 'rgb(35, 161, 180)',
  Wine: '#6C0A32',
}

export default Color
