import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import loadable from '@loadable/component'
import { AppConfig } from './utils/config'
import { reportError } from './utils/error'
import client from './apolloClient'

import StoreDetail from './pages/StoreDetail'
import NotFoundPage from './pages/NotFound'
import LanguageContextProvider from './providers/LanguageContextProvider'
import ThemeContextProvider from './providers/ThemeContextProvider'
import StripeContextProvider from './providers/StripeContextProvider'
import SessionContextProvider from './providers/SessionContextProvider'
import Loading from './components/Loading'
import AdminUserList from './pages/AdminUserList'

const Checkout = loadable(() => import('./pages/Checkout'))
const PaymentSelect = loadable(() => import('./pages/PaymentSelect'))
const CardPay = loadable(() => import('./pages/CardPay'))
const Pickup = loadable(() => import('./pages/Pickup'))
const AdminStoreList = loadable(() => import('./pages/AdminStoreList'))
const AdminStoreDetail = loadable(() => import('./pages/AdminStoreDetail'))
const AdminStoreOrders = loadable(() => import('./pages/AdminStoreOrders'))
const AdminOrder = loadable(() => import('./pages/AdminOrder'))
const AdminUser = loadable(() => import('./pages/AdminUser'))
const AdminCampaign = loadable(() => import('./pages/AdminCampaign'))
const AdminCampaignList = loadable(() => import('./pages/AdminCampaignList'))
const AuthCallback = loadable(() => import('./pages/AuthCallback'))
const PrivateRoute = loadable(() => import('./components/PrivateRoute'))
const Login = loadable(() => import('./pages/Login'))

require('./App.css')

if (AppConfig.SENTRY_ENDPOINT) {
  Sentry.init({ dsn: AppConfig.SENTRY_ENDPOINT })
}

// eslint-disable-next-line react/prop-types
function SwitchRouter({ children }) {
  const history = useHistory()
  useEffect(() => {
    const unListen = history.listen((location) => {
      // eslint-disable-next-line no-undef
      gtag('config', AppConfig.GA_ID, { page_path: location.pathname })
    })
    return () => unListen()
  }, [])

  return (
    <Switch>{children}</Switch>
  )
}

export default class App extends Component {
  componentDidCatch(e) {
    console.warn('App componentDidCatch', e)
    reportError(e, { message: 'componentDidCatch react error' })
  }

  render() {
    const { theme } = this.props
    return (
      <ApolloProvider client={client}>
        <Route path="/:locale?">
          <LanguageContextProvider>
            <ThemeContextProvider initialTheme={theme}>
              <SessionContextProvider>
                <StripeContextProvider>
                  <SwitchRouter>
                    {/* Limited Access */}
                    <PrivateRoute fallback={<Loading />} path="/admin/stores" component={AdminStoreList} />
                    <PrivateRoute fallback={<Loading />} path="/admin/users" component={AdminUserList} />
                    <PrivateRoute fallback={<Loading />} path="/admin/store/:storeId/orders" component={AdminStoreOrders} />
                    <PrivateRoute fallback={<Loading />} path="/admin/store/:storeId" component={AdminStoreDetail} />
                    <PrivateRoute fallback={<Loading />} path="/admin/order/:orderId" component={AdminOrder} />
                    <PrivateRoute fallback={<Loading />} path="/admin/user/:userId" component={AdminUser} />
                    <PrivateRoute fallback={<Loading />} path="/admin/campaign/:campaignId" component={AdminCampaign} />
                    <PrivateRoute fallback={<Loading />} path="/admin/campaigns" component={AdminCampaignList} />
                    <Route path="/:locale?/store/:storeId" component={StoreDetail} />
                    <Route fallback={<Loading />} path="/:locale?/checkout" component={Checkout} />
                    <Route fallback={<Loading />} path="/:locale?/payment-select" component={PaymentSelect} />
                    <Route fallback={<Loading />} path="/:locale?/card-pay" component={CardPay} />
                    <Route fallback={<Loading />} path="/:locale?/native-pay" component={PaymentSelect} />
                    <Route fallback={<Loading />} path="/:locale?/pickup" component={Pickup} />
                    <Route fallback={<Loading />} path="/:locale?/login" component={Login} />
                    <Route fallback={<Loading />} path="/:locale?/auth/callback" component={AuthCallback} />
                    <Route component={NotFoundPage} />
                  </SwitchRouter>
                </StripeContextProvider>
              </SessionContextProvider>
            </ThemeContextProvider>
          </LanguageContextProvider>
        </Route>
      </ApolloProvider>
    )
  }
}

App.propTypes = {
  theme: PropTypes.string,
}

App.defaultProps = {
  theme: null,
}
