/* eslint-disable no-plusplus */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const fade = keyframes`
    0% {
        opacity: 1
    }
    100% {
        opacity: 0;
    }
`

const getBars = ({
  countBars, color, size = 1, barSize, sizeUnit = 'rem',
}) => {
  const bars = []
  for (let i = 0; i < countBars; i++) {
    const rotate = (360 / countBars) * i
    const translate = -(size / 2)
    bars.push(
      <Bar
        countBars={countBars}
        color={color}
        barSize={barSize}
        size={size}
        rotate={rotate}
        translate={translate}
        key={i.toString()}
        index={i}
        sizeUnit={sizeUnit}
      />,
    )
  }
  return bars
}

const Spinner = ({
  size, color, loading, sizeUnit,
}) => {
  const radius = size / 2
  const countBars = 16
  return (
    loading && (
      <Wrapper size={size} sizeUnit={sizeUnit}>
        {getBars({
          countBars,
          radius,
          color,
          size,
          sizeUnit,
        })}
      </Wrapper>
    )
  )
}

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => `${props.size}${props.sizeUnit}`};
    height: ${props => `${props.size}${props.sizeUnit}`};
`

const Bar = styled.div`
    position: absolute;
    width: ${props => `${props.size / 10}${props.sizeUnit}`};
    height: ${props => `${props.size / 4}${props.sizeUnit}`};
    background-color: ${({ theme, color }) => color || theme.color.primary};
    opacity: 0.05;
    transform: ${props => `rotate(${props.rotate}deg)`}
        ${props => `translate(0, ${props.translate}${props.sizeUnit})`};
    animation: ${fade} ${props => props.countBars * 0.06}s linear infinite;
    animation-delay: ${props => props.index * 0.06}s;
`

Spinner.defaultProps = {
  loading: true,
  size: 1,
  color: '',
  sizeUnit: 'rem',
}

Spinner.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  sizeUnit: PropTypes.string,
}

export default Spinner
