/* eslint-disable no-underscore-dangle */
import ApolloClient from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { Observable } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { RetryLink } from 'apollo-link-retry'
import moment from 'moment'
import { fragmentMatcher } from './graphql/fragments'
import { AppConfig } from './utils/config'
import { getGlobalContext, isBrowser } from './utils/window'
import {
  deleteUser, getUser, saveUser,
} from './utils/localStorage'
import { createAnonUserMutation, refreshAccessTokenMutation } from './graphql/mutations'
import {
  sessionQuery, userQuery,
} from './graphql/queries'
import { reportError } from './utils/error'

if (AppConfig.STAGE !== 'PROD') {
  global.fetch = require('node-fetch')
}

Object.setPrototypeOf = Object.setPrototypeOf || ((obj, proto) => {
  // eslint-disable-next-line no-param-reassign,no-proto
  obj.__proto__ = proto
  return obj
})

const apolloUri = AppConfig.APOLLO_ENDPOINT // https://colugo.pickpack.de

const isAuth0User = user => user && !user.refresh_token && user.access_token && user.access_token.startsWith('ey')
const isAnonUser = user => user && user.anon_user_id && user.refresh_token

const errorLink = onError(({
  graphQLErrors, networkError, operation, forward,
// eslint-disable-next-line consistent-return
}) => {

  if (networkError) {
    reportError(`[Network error]: ${networkError}`)
  }

  // handle token expiration
  // based on https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
  if (networkError && networkError.result) {
    // User access token has expired or is invalid
    if (networkError.result.message === 'Unauthorized') {

      user = getUser()
      if (isAuth0User(user)) {
        if (getGlobalContext().usesPrivateRoute) {
          // redirect to login route
          if (isBrowser) {
            if (window.location.pathname.startsWith('/login')) {
              window.location = '/login'
            } else {
              window.location = `/login?redirect=${window.decodeURIComponent(window.location.pathname)}`
            }
          }
        } else {
          // an Auth0 user is not required, delete the unauthorized user and reload
          // a better way would be to create a new anon user here and retry the apollo operation
          // but since this only happens to admin (Auth0) users this is okay right now
          deleteUser()
          if (isBrowser) window.location.reload()
        }
      } else if (isAnonUser(user)) {
        console.log('refreshing token for user', user.anon_user_id)
        return new Observable((observer) => {

          // using another apollo client with the authorization field set to ANONYMOUS
          // otherwise this would call itself
          NoAuthClient.mutate({
            mutation: refreshAccessTokenMutation,
            variables: {
              user_id: user.anon_user_id,
              refresh_token: user.refresh_token,
            },
          }).then(async (res) => {
            console.log('refresh access_token success')
            const newUser = { ...user, access_token: res.data.refreshAccessToken.access_token }
            user = saveUser(newUser)
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: `Bearer ${user.access_token}`,
              },
            }))

          }).then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            }
            // Retry last failed request
            forward(operation).subscribe(subscriber)
          }).catch((err) => {
            reportError('refreshAccessTokenMutation error', err)
            if (Array.isArray(err.graphQLErrors) && (err.graphQLErrors[0] === 'USER_NOT_FOUND' || err.graphQLErrors[0] === 'INVALID_REFRESH_TOKEN')) {
              // if the user doesn't exist in the db for some reason or the refresh token is invalid
              // there is no way to do a re-auth, so delete the local user
              reportError(`${err.graphQLErrors[0]} while refreshing access_token. Deleting local user`)
              deleteUser()
            }
            observer.error(err)
          })
        })
      } else {
        reportError('Cannot handle unauthorized response', user)
      }
    }
  }

  if (graphQLErrors) {
    graphQLErrors.map(error => reportError(`[GraphQL error]: Message: ${JSON.stringify(error)}`))
  }
})

let isInitialUserCreationOngoing = false
let user = null

const initialUserCreation = async () => {
  if (isInitialUserCreationOngoing) return
  isInitialUserCreationOngoing = true

  client.mutate({
    mutation: createAnonUserMutation,
    variables: {
      session_device_id: 'device-id',
    },
  }).then(async (res) => {
    const { expires_in: expiry, __typename, ...newUser } = res.data.createAnonUser
    user = saveUser(newUser)

    const session = await client.readQuery({ query: sessionQuery })
    const { data } = await client.query({
      query: userQuery,
      variables: { id: user.anon_user_id },
      fetchPolicy: 'network-only',
    })

    if (data && data.user) {
      await client.writeData({
        data: {
          session: {
            ...session,
            user: { ...data.user, __typename: 'User' },
            __typename: 'Session',
          },
        },
      })
    }

    isInitialUserCreationOngoing = false
    console.log('Created a new User:', newUser)
  }).catch((err) => {
    isInitialUserCreationOngoing = false
    reportError('createAnonUserMutation error', err)
  })
}

function retrieveUserFromUrl(params) {
  NoAuthClient.mutate({
    mutation: refreshAccessTokenMutation,
    variables: {
      user_id: params.user_id,
      refresh_token: params.token,
    },
  }).then(async (res) => {
    const { access_token } = res.data.refreshAccessToken
    user = saveUser({ access_token, anon_user_id: params.user_id, refresh_token: params.token })
    const { data } = await client.query({ query: userQuery, variables: { id: user.anon_user_id } })
    const session = await client.readQuery({ query: sessionQuery })
    const newSession = {
      ...session,
      user: { ...data.user, __typename: 'User' },
      __typename: 'Session',
    }
    await client.writeData({ data: { session: newSession } })
  }).catch((err) => {
    reportError(err, { message: 'refreshAccessTokenMutation error' })
    if (Array.isArray(err.graphQLErrors) && (err.graphQLErrors[0] === 'USER_NOT_FOUND' || err.graphQLErrors[0] === 'INVALID_REFRESH_TOKEN')) {
      // if the user doesn't exist in the db for some reason or the refresh token is invalid
      // there is no way to do a re-auth, so delete the local user
      reportError(`${err.graphQLErrors[0]} while refreshing access_token. Deleting local user`)
      deleteUser()
    }
  })
}

const authLink = setContext(async (_, { headers }) => {
  try {
    // Note: in server side rendering, we can't access the users local storage
    // do we use server side rendering for queries where the result is user specific?
    // If so, we should save the anon_user_token as a cookie, but then we need to be careful
    // because the refresh_token should not be set as a cookie and only be set in local_storage
    let token = null
    let params = {}

    if (isBrowser) {
      user = getUser()

      if (window.location.search.startsWith('?')) {
        params = (decodeURIComponent(window.location.search.slice(1)).split('&') || []).reduce((acc, keyValue) => {
          const [key, value] = keyValue.split('=')
          return { ...acc, [key]: value }
        }, {})
      }

      if (params.user_id && params.token && (user || {}).anon_user_id !== params.user_id) {
        // overwrite user if user retrieved from url params is different from user in localStorage
        setTimeout(() => retrieveUserFromUrl(params), 0)
      } else if (user && user.access_token) {
        // use token of user in localStorage
        token = user.access_token
      } else {
        // otherwise, create a new user
        setTimeout(initialUserCreation, 0)
      }
    }

    const authHeader = token ? `Bearer ${token}` : 'ANONYMOUS'

    return {
      headers: {
        ...headers,
        authorization: authHeader,
        'Accept-Language': getGlobalContext().pickpackLocale || 'de',
      },
    }
  } catch (err) {
    reportError('AuthContextError', err)
    throw (new Error(err))
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 800,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: Infinity,
    retryIf: (error, operation) => {
      if (error.networkError && error.networkError.statusCode === 401) return false
      console.log('retry?', { operation })
      // const queryName = operation.query.definitions[0].selectionSet.selections[0].name.value
      // if (operation.query.definitions[0].operation !== 'mutation'
      //     || queryName === 'addFavoriteStore'
      //     || queryName === 'removeFavoriteStore'
      // ) {
      //   return true
      // }
      return false
    },
  },
})

const httpLink = new HttpLink({ uri: apolloUri })

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'BasketItem': return `BasketItem:${object.id}`
      case 'Extra': return `Extra:${object.extra_id}`
      default: return (object.id)
    }
  },
  fragmentMatcher,
  cacheRedirects: {
    Query: {
      product: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Product', id: args.id }),
      store: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Store', id: args.id }),
      order: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Order', id: args.id }),
    },
  },
})

// https://www.apollographql.com/docs/react/essentials/local-state/
/* INITIAL STATE: use apollo state rather than redux */
cache.writeData({
  data: {
    session: {
      user: null,
      basket: null,
      store: null,
      order: null,
      pickup: { time: moment().toISOString(), isInstantOrder: false, __typename: 'Pickup' },
      __typename: 'Session',
    },
  },
})

const resolvers = {
  Query: {
    session: sessionData => ({ __typename: 'Session', ...sessionData }),
  },
}

const client = new ApolloClient({
  ssrMode: !isBrowser,
  link: authLink.concat(retryLink).concat(errorLink).concat(httpLink),
  // @FIXME: https://github.com/apollographql/apollo-server/issues/3058
  // name: 'web',
  // version: '2.6.3',
  cache: isBrowser ? cache.restore(window.__APOLLO_STATE__) : cache,
  resolvers,
})

const noAuthLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: 'ANONYMOUS',
  },
}))

const noAuthErrorLink = onError(({
  graphQLErrors, networkError,
}) => {
  if (graphQLErrors) {
    graphQLErrors.map(error => reportError(`[GraphQL error]: Message: ${JSON.stringify(error)}`))
  }
  if (networkError) {
    reportError(`[Network error]: ${networkError}`)
  }
})

// this client always identifies itself with the ANONYMOUS auth header
const NoAuthClient = new ApolloClient({
  ssrMode: !isBrowser,
  link: noAuthLink.concat(noAuthErrorLink).concat(httpLink),
  // @FIXME: https://github.com/apollographql/apollo-server/issues/3058
  // name: 'web',
  // version: '2.6.3',
  cache: new InMemoryCache(),
  resolvers,
})

/** SSR with data(SEO) or without data(Initial page loading speed)
// import { getDataFromTree } from '@apollo/react-ssr'
// import apolloClient from './apolloClient'
// try {
//   await getDataFromTree(<Root />)
// } catch (e) {
//   console.log(e)
// }
// <script>window.__APOLLO_STATE__ = ${JSON.stringify(apolloClient.extract()).replace(/</g, '\\u003c')}</script>
*/

export default client
