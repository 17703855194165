import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import { HeaderSmall } from '../Text'

const Row = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
`

const Text = styled(HeaderSmall)`
  padding-left: 0.5rem;
`

function IconText({
  icon, children, color, ...props
}) {
  return (
    <Row {...props}>
      <Icon name={icon} color={color} />
      <Text color={color}>{children}</Text>
    </Row>
  )
}

IconText.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
}

IconText.defaultProps = {
  children: null,
  color: null,
}

export default IconText
