import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import loadable from '@loadable/component'
import moment from 'moment'
import Modal from '../../../components/Modal'
import Icon from '../../../components/Icon'
import { ELLIPSIS, FLEX_CENTER_CENTER } from '../../../utils/styles'
import { SessionContext } from '../../../providers/SessionContextProvider'
import usePickupTimer from '../../../hooks/usePickupTimer'
import Loading from '../../../components/Loading'
const TimePicker = loadable(() => import('../../../components/TimePicker'))

const Container = styled.div`
  min-width: 0;
  margin-right: 4px;
  margin-top: 5px;
`

const ClockIcon = styled(Icon)`
  font-size: 1.2rem;
`

const Button = styled.button`
  background: ${({ theme }) => theme.color.primary};
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  ${FLEX_CENTER_CENTER};
`

const Label = styled.div`
  margin-left: 0.5rem;
  ${ELLIPSIS};
`

const Sheet = styled.div`
  background: #fff;
  flex-direction: column;
  width: 100%;
  margin: auto;
  border-radius: 4px;
  ${FLEX_CENTER_CENTER};
`

function PickupTimeLabel({ pickup }) {
  if (pickup && pickup.time) {
    if (pickup.isInstantOrder) {
      return <FormattedMessage id="components.TimePicker.PickupAsap" />
    }
    return (
      <FormattedMessage
        id="components.TimePicker.ResolveButton"
        values={{ time: moment(pickup.time).format('H:mm') }}
      />
    )
  }
  return 'Set Pickup Time'
}

PickupTimeLabel.propTypes = {
  pickup: PropTypes.object,
}

PickupTimeLabel.defaultProps = {
  pickup: { time: null, isInstantOrder: false },
}

function PickupTimeSelector({ store }) {
  const [open, setOpen] = useState(false)
  const { pickup, writeCache } = useContext(SessionContext)
  usePickupTimer({ store })

  function toggleModal() {
    setOpen(prevOpen => !prevOpen)
  }

  async function handleChange({ time, isInstantOrder = false }) {
    const pickUp = { time: time.toISOString(), isInstantOrder, __typename: 'Pickup' }
    writeCache({ pickup: pickUp })
    toggleModal()
  }

  return (
    <Container>
      <Button onClick={toggleModal}>
        <ClockIcon name="clock" />
        <Label><PickupTimeLabel pickup={pickup} /></Label>
      </Button>
      {open && store && (
        <Modal onClose={toggleModal}>
          <Sheet>
            <TimePicker
              fallback={<Loading />}
              store={store}
              onChange={handleChange}
            />
          </Sheet>
        </Modal>
      )}
    </Container>
  )
}

PickupTimeSelector.propTypes = {
  store: PropTypes.object,
}

PickupTimeSelector.defaultProps = {
  store: null,
}

export default PickupTimeSelector
