import { isBrowser } from './window'

function checkAvailability(shouldThrowError = true) {
  if (!isBrowser) return false

  if (!localStorage) {
    if (shouldThrowError) {
      throw new Error('Your browser does not support local storage')
    } else {
      return false
    }
  }

  return true
}

export const basketId = isBrowser && getItem('basketId')
  ? getItem('basketId')
  : 'no-basket'

export const orderId = isBrowser && getItem('orderId')
  ? getItem('orderId')
  : 'no-order'

export const storeId = isBrowser && getItem('storeId')
  ? getItem('storeId')
  : 'no-store'


export function getUser() {
  if (checkAvailability(false)) {
    return getItem('user')
  }
  return null
}

export const saveUser = (newUser) => {
  if (checkAvailability()) {
    setItem('user', newUser)
  }
  return newUser
}

export const deleteUser = () => {
  if (checkAvailability()) {
    (localStorage || sessionStorage).removeItem('user')
  }
}

export function setDefaultPay(method) {
  if (checkAvailability()) {
    setItem('preferred-payment', method)
  }
  return method
}

export function getDefaultPay() {
  if (checkAvailability()) {
    return getItem('preferred-payment')
  }
  return null
}

export function getItem(key, fromSessionStorage = false) {
  if (checkAvailability(false)) {
    if (fromSessionStorage) {
      return JSON.parse((sessionStorage || localStorage).getItem(key))
    }
    return JSON.parse((localStorage || sessionStorage).getItem(key))
  }
  return null
}

export function setItem(key, value, toSessionStorage = false) {
  if (checkAvailability()) {
    if (toSessionStorage) {
      (sessionStorage || localStorage).setItem(key, JSON.stringify(value))
    } else {
      (localStorage || sessionStorage).setItem(key, JSON.stringify(value))
    }
  }
  return value
}

export function removeItem(key) {
  if (checkAvailability()) {
    (localStorage || sessionStorage).removeItem(key)
  }
  return null
}

export function clearAll() {
  if (checkAvailability()) {
    localStorage.clear()
    sessionStorage.clear()
  }
}
