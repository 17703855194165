import React from 'react'
// import styled from 'styled-components'
import LoadingSpinner from '../LoadingSpinner'
// import Logo from '../../images/Logo_Standard.png'
// import Color from '../../utils/color'

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

// const LoadingLogo = styled.img`
//   margin-bottom: 2rem;
// `

function Loading() {
  return (
    <div style={styles.container}>
      {/* <LoadingLogo src={Logo} alt="loading-screen-logo" width="150px" height="" /> */}
      <LoadingSpinner />
    </div>
  )
}

export default Loading
