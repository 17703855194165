import React, { useEffect, useRef } from 'react'
import * as ReactDOM from 'react-dom'
import styled from 'styled-components'
import { isBrowser } from '../../utils/window'
import zIndex from '../../utils/zIndex'

const DarkOverlay = styled.div`
  position: fixed;
  overflow: scroll;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.modal};
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`

export const Sheet = styled.div`
  width: 100%;
  margin: auto;
  border-radius: 4px;
`

function Modal({ onClose, children }) {
  const darkOverlayRef = useRef(null)
  const modalRoot = isBrowser && document.getElementById('modal-root')
  let scrollTop = 0

  useEffect(() => {
    scrollTop = window.scrollY
    document.body.style.top = `-${window.scrollY}px`
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
      document.body.style.removeProperty('top')
      window.scrollTo(0, parseInt(scrollTop, 10))
    }
  }, [])

  function onDarkOverlayClick(e) {
    if (e.nativeEvent.target === darkOverlayRef.current) {
      onClose()
    }
  }

  return modalRoot
    ? ReactDOM.createPortal(
      <DarkOverlay onMouseDown={onDarkOverlayClick} touchstart={onDarkOverlayClick} ref={darkOverlayRef}>
        <Sheet onClick={e => e.stopPropagation()}>
          {children}
        </Sheet>
      </DarkOverlay>,
      modalRoot
    )
    : null
}

export default Modal
