import React, {
  useRef, useContext, useMemo, memo,
} from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { FormattedMessage } from 'react-intl'
import loadable from '@loadable/component'
import { storeQuery } from '../../graphql/queries'
import FooterButton from '../../components/FooterButton'
import Loading from '../../components/Loading'
import {
  StoreImageContainer,
  StoreImage,
  BottomButtonsContainer,
  StoreName,
  StoreLocation,
  ImageOverlay,
  BottomContainer,
  FooterButtonText,
} from './styled'
import PickupTimeSelector from './PickupTimeSelector'
import { formatPrice } from '../../utils/formatter'
import { SessionContext } from '../../providers/SessionContextProvider'
import Header from '../../components/Header'
import ActiveOrderBanner from '../../components/ActiveOrderBanner'
import useIntersection from '../../hooks/useIntersection'
import PageLayout, { Footer } from '../PageLayout'
import { setItem } from '../../utils/localStorage'
const Menu = loadable(() => import('./Menu'))

// testable storeId
// str_u1jrt15jbudc
// str_u1jrte2sudch
// str_u1jrtcx1zx81

function hasActiveOrder(order) {
  if (!order || !order.status) return false
  const nonActiveList = [
    'PICKED_UP',
    'PICKED_UP_TIMEOUT',
    'COMPLETED',
    'CANCELED',
    'CANCELED_STORE',
    'CANCELED_USER',
  ]
  return !nonActiveList.includes(order.status)
}

function StoreDetail({
  history,
  match: {
    params: { storeId },
  },
}) {
  const {
    basket, writeCache, order, store: currentStore,
  } = useContext(SessionContext)
  const headerRef = useRef()
  const timePickerThreshold = useRef()
  const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 50
  const nonStickyHeader = useIntersection(timePickerThreshold, {
    initialValue: true,
    threshold: 0.25,
    options: { threshold: [0, 0.25, 0.5, 0.75, 1.0] },
  })

  const {
    data = { store: null },
  } = useQuery(storeQuery, {
    variables: { id: storeId },
    onCompleted: async ({ store: storeToCache }) => {
      if (storeToCache && (currentStore || {}).id !== storeToCache.id) {
        setItem('storeId', storeToCache.id)
        writeCache({ store: storeToCache })
      }
    },
  })

  const { store } = data

  function navToBasket() {
    history.push('/checkout')
  }

  function navToPickupScreen() {
    history.push('/pickup')
  }

  const shouldShowNavBasket = !!basket && !!basket.items.length
  const shouldShowOrderBanner = useMemo(() => hasActiveOrder(order), [order])

  return (
    <PageLayout
      noPaddingTop
      header={(
        <Header drawBehind={nonStickyHeader} setRef={headerRef}>
          {nonStickyHeader || <PickupTimeSelector store={store} />}
        </Header>
      )}
      footer={
        shouldShowOrderBanner || shouldShowNavBasket ? (
          <>
            {shouldShowOrderBanner && (
              <ActiveOrderBanner
                pickup_time={order.pickup_time}
                onClick={navToPickupScreen}
              />
            )}
            {shouldShowNavBasket && (
              <Footer>
                <FooterButton onClick={navToBasket}>
                  <FooterButtonText>
                    <FormattedMessage id="pages.StoreDetail.NavToBasket" />
                    {` ( ${formatPrice(basket.item_total)} )`}
                  </FooterButtonText>
                </FooterButton>
              </Footer>
            )}
          </>
        ) : null
      }
    >
      <StoreImageContainer>
        <ImageOverlay />
        {store && <StoreImage src={store.banner} />}
        <BottomContainer>
          <StoreName ref={timePickerThreshold}>{store && store.name.toUpperCase()}</StoreName>
          <StoreLocation icon="mapPin" color="#fff">
            {store && ` ${store.location_name}`}
          </StoreLocation>
          <BottomButtonsContainer>
            <PickupTimeSelector store={store} />
          </BottomButtonsContainer>
        </BottomContainer>
      </StoreImageContainer>

      <Menu
        fallback={<Loading />}
        storeId={storeId}
        basket={basket}
        headerHeight={headerHeight}
      />
    </PageLayout>
  )
}

StoreDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default memo(StoreDetail)
