import * as Sentry from '@sentry/browser'
import { isBrowser } from './window'
// import { AppConfig } from './config'

export function reportError(error, info) {
  if (isBrowser && !window.location.host.includes('localhost')) {
    Sentry.withScope((scope) => {
      // scope.setTag('my-tag', 'my value')
      scope.setLevel('error')
      Sentry.captureMessage(JSON.stringify({ error, info }))
    })
  } else {
    console.error(error)
  }
}

export function handleGraphQLErrors({ graphQLErrors }) {
  if (!graphQLErrors) return null
  switch (graphQLErrors[0]) {
    case 'ORDER_TRANSACTION_FAILED':
      return 'ORDER_TRANSACTION_FAILED'
    case 'ORDER_TRANSACTION_FAILED_ABOVE_MAX_TOTAL':
      return 'ORDER_TRANSACTION_FAILED_ABOVE_MAX_TOTAL'
    case 'ORDER_TRANSACTION_FAILED_NO_FUNDS':
      return 'ORDER_TRANSACTION_FAILED_NO_FUNDS'
    case 'ORDER_TRANSACTION_FAILED_CARD_EXPIRED':
      return 'ORDER_TRANSACTION_FAILED_CARD_EXPIRED'
    case 'ORDER_TRANSACTION_FAILED_AMOUNT_TOO_SMALL':
      return 'ORDER_TRANSACTION_FAILED_AMOUNT_TOO_SMALL'
    case 'ORDER_TRANSACTION_FAILED_BLACKLISTED':
      return 'ORDER_TRANSACTION_FAILED_BLACKLISTED'
    case 'ACTIVATE_INVALID_PROMOTION':
      return 'ACTIVATE_INVALID_PROMOTION'
    case 'APPLY_PROMOTION_ALREADY_ACTIVE':
      return 'APPLY_PROMOTION_ALREADY_ACTIVE'
    case 'APPLY_PROMOTION_NOT_FOR_EMAIL_DOMAIN':
      return 'APPLY_PROMOTION_NOT_FOR_EMAIL_DOMAIN'
    case 'APPLY_PROMOTION_ONLY_ONE_NEW':
      return 'APPLY_PROMOTION_ONLY_ONE_NEW'
    case 'APPLY_PROMOTION_ONLY_FOR_NEW_USERS':
      return 'APPLY_PROMOTION_ONLY_FOR_NEW_USERS'
    default:
      return 'GENERAL_ORDER_ERROR'
      // if (graphQLErrors[0] && graphQLErrors[0].startsWith('APPLY_PROMOTION')) {
      //   return 'Es gab Probleme beim Einlösen deines Gutscheincodes: Bestell diesmal doch einfach ohne oder schau auf unseren Social Media Kanälen vorbei um dich über die aktuellsten Promo-Aktionen zu informieren.'
      // }
      // return 'Fehler bei der Abwicklung deiner Bestellung. Versuche es erneut.'
  }
}

export function handlePromoCodeError({
  graphQLErrors, message, user, promotionCode,
}) {
  if (graphQLErrors) {
    switch (graphQLErrors[0]) {
      case 'APPLY_PROMOTION_ALREADY_ACTIVE':
        return { id: 'APPLY_PROMOTION_CODE_ALREADY_ACTIVE', vars: { promotionCode } }
      case 'INVALID_PROMOTION_CODE':
        return { id: 'INVALID_PROMOTION_CODE', vars: { promotionCode } }
      case 'USER_NOT_VERIFIED':
        return { id: user.is_anon ? 'ANON_USER_NOT_VERIFIED' : 'USER_NOT_VERIFIED' }
      case 'APPLY_PROMOTION_CODE_NOT_FOR_EMAIL_DOMAIN':
        return { id: 'APPLY_PROMOTION_NOT_FOR_EMAIL_DOMAIN' }
      case 'APPLY_PROMOTION_ONLY_ONE_NEW':
        return { id: 'APPLY_PROMOTION_ONLY_ONE_NEW' }
      case 'APPLY_PROMOTION_ONLY_FOR_NEW_USERS':
        return { id: 'APPLY_PROMOTION_ONLY_FOR_NEW_USERS' }
      default:
        return { id: 'GENERAL_PROMOCODE_ERROR' }
    }
  } else if (message && message === 'NO_PROMOTION_CODE') {
    return { id: 'NO_PROMOTION_CODE' }
  } else {
    return { id: 'GENERAL_PROMOCODE_ERROR' }
  }
}


// Capture exceptions, messages or manual events
// Sentry.captureMessage('Hello, world!')
// Sentry.captureException(new Error('Good bye'))
// Sentry.captureEvent({
//   message: 'Manual',
//   stacktrace: [
//     // ...
//   ],
// })

// Set user information, as well as tags and further extras
// Add a breadcrumb for future events
// Sentry.addBreadcrumb({
//   message: 'My Breadcrumb',
//   // ...
// })
