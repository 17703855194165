/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import {
  sessionQuery, getUser as fetchMe, activeOrderQuery, storeBasket, storeQuery,
} from '../graphql/queries'
import { setItem, orderId, removeItem } from '../utils/localStorage'
import { isBrowser, isFBB } from '../utils/window'
import { reportError } from '../utils/error'

export const SessionContext = createContext()

function SessionContextProvider({ children }) {
  const client = useApolloClient()
  const { data, loading } = useQuery(sessionQuery, { skip: !isBrowser })
  const { session } = data || {}

  async function read() {
    try {
      const { session: _session } = await client.readQuery({ query: sessionQuery })
      return _session
    } catch (error) {
      return reportError(error, { message: 'Failed: read()' })
    }
  }

  async function write(newSession) {
    try {
      const sessionCache = await read()
      const newQuery = { query: sessionQuery, data: { session: { ...sessionCache, ...newSession } } }
      const updatedSession = await client.writeQuery(newQuery)
      return updatedSession
    } catch (error) {
      return reportError(error, { message: 'Failed: write()' })
    }
  }

  async function triggerAuthContext() {
    try {
      // [GraphQL error]: Message: "Cannot read property 'id' of null"
      // {"errors":["Cannot read property 'id' of null"],"data":{"getUser":null}}
      // the first execution might fail with 401 but it triggers setting auth context.
      const { data: userRes } = await client.query({ query: fetchMe })
      if ((userRes || {}).getUser) {
        write({ user: userRes.getUser })
      }
    } catch (error) {
      reportError(error, { message: 'Failed: triggerAuthContext()', session })
    }
  }

  async function fetchOrderIfExists() {
    try {
      if (orderId.startsWith('ord')) {
        const { data: orderRes } = await client.query({ query: activeOrderQuery, variables: { id: orderId }, fetchPolicy: 'network-only' })
        if ((orderRes || {}).order) write(orderRes)
      }
    } catch (error) {
      reportError(error, { message: 'Failed: fetchOrderIfExists()', orderId })
    }
  }

  async function fetchOrCreateBasket(store_id, user) {
    if (!store_id) return
    const NO_FEE_FBB = 'cpg_no_fee_fbb'
    const no_fee_campaign_id = isFBB ? NO_FEE_FBB : null
    const activeCampaign = user && (user.active_campaigns || [])[0]
    const campaign_id = (activeCampaign || {}).id
    const campaigns = { no_fee_campaign_id, campaign_id }
    try {
      const { data: res } = await client.query({
        query: storeBasket,
        variables: { store_id, campaigns },
        fetchPolicy: 'network-only',
      })
      if (res && res.storeBasket) {
        await write({ basket: res.storeBasket })
      }
    } catch (error) {
      reportError(error, { message: 'Failed: fetchOrCreateBasket()' })
    }
  }

  async function fetchStore(storeId) {
    const { data: storeRes } = await client.query({ query: storeQuery, variables: { id: storeId } })
    if (storeRes && storeRes.store) {
      write(storeRes)
      setItem('storeId', storeRes.store.id)
    }
  }

  async function clearBasketAfterOrder(_order) {
    await write({ order: _order, basket: null, store: null })
    removeItem('storeId')
    removeItem('hasShownSuccessModal')
  }

  useEffect(() => {
    triggerAuthContext()
    fetchOrderIfExists()
    return () => {
      removeItem('storeId')
    }
  }, [])

  useEffect(() => {
    let params = {}
    if (window.location.search.startsWith('?')) {
      params = (decodeURIComponent(window.location.search.slice(1)).split('&') || []).reduce((acc, keyValue) => {
        const [key, value] = keyValue.split('=')
        return { ...acc, [key]: value }
      }, {})
    }

    if (params.pay_type) {
      setItem('preferred-payment', params.pay_type)
    }

    if (session.user) {
      if (session.store) {
        fetchOrCreateBasket(session.store.id, session.user)
      } else if (params.store_id) {
        fetchStore(params.store_id)
      }
    }

    if (session.order && session.order.id.startsWith('ord')) {
      setItem('orderId', session.order.id)
    }
  }, [session])

  console.log({ ...session })
  return (
    <SessionContext.Provider
      value={{
        ...session, readCache: read, writeCache: write, loading, clearBasketAfterOrder,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

SessionContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default SessionContextProvider
