import gql from 'graphql-tag'
import * as fragments from './fragments'

// export const registerSourceMutation = gql`
//   mutation($user_id:ID!, $token:String!, $fraud_payload: SepaFraudCheck){
//     registerSource(user_id: $user_id, token: $token, fraud_payload: $fraud_payload) {
//       ...UserFragment
//     }
//   }
//   ${fragments.userFragment}
// `

// export const deleteSourceMutation = gql`
//   mutation($user_id:ID!, $source_id:ID!){
//     deleteSource(user_id: $user_id, source_id: $source_id) {
//       ...UserFragment
//     }
//   }
//   ${fragments.userFragment}
// `

// export const setDefaultSourceMutation = gql`
//   mutation($user_id:ID!, $source_id:ID!){
//     setDefaultSource(user_id: $user_id, source_id: $source_id) {
//       ...UserFragment
//     }
//   }
//   ${fragments.userFragment}
// `

export const createAnonUserMutation = gql`
  mutation createAnonUser($session_device_id: ID){
    createAnonUser(session_device_id: $session_device_id){
      access_token
      refresh_token
      anon_user_id
      expires_in
    }
  }
`

export const refreshAccessTokenMutation = gql`
  mutation refreshAccessToken($user_id: ID, $refresh_token: String){
    refreshAccessToken(user_id: $user_id, refresh_token: $refresh_token){
      access_token,
      expires_in
    }
  }
`

// export const createOrderMutation = gql`
//   mutation createOrder(
//   $order: CreateOrder!) {
//     createOrder(order: $order) {
//       ...OrderFragment
//     }
//   }
//   ${fragments.orderFragment}
// `

export const createOrderMutation = gql`
  mutation createOrderFromBasket(
  $order: CreateOrderFromBasket!) {
    createOrderFromBasket(order: $order) {
      ...OrderFragment
    }
  }
  ${fragments.orderFragment}
`

export const updateOrderMutation = gql`
  mutation updateOrder($id: ID!, $status: StatusEnum, $pickup_time: String, $paypal: PaypalDataInput, $feedback: String, $user_rating: OrderUserRating) {
    updateOrder(
      id: $id,
      order: {
        status: $status,
        pickup_time: $pickup_time,
        paypal_info: $paypal,
        feedback: $feedback,
        user_rating: $user_rating
      }) {
      id
      code_text
      code_emoji
      total
      status
      pickup_time
      paypal_info {
        paymentId
        authorizationId
        captureId
        refundId
      }
    }
  }
`

export const applyPromotionMutation = gql`
    mutation applyPromotion($user_id: ID!, $promotion_code: String!) {
        applyPromotion(
            user_id: $user_id,
            promotion_code: $promotion_code
        ) {
            ...UserFragment
        }
    }
    ${fragments.userFragment}
`

export const updateUserMutation = gql`
  mutation updateUser($id:ID!, $user: UpdateUser!) {
    updateUser(id:$id, item: $user) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`
export const updateCampaignMutation = gql`
  mutation updateCampaign($id:ID!, $payload: CampaignInput!) {
    updateCampaign(id: $id, payload: $payload) {
      ...AdminCampaignFragment
    }
  }
  ${fragments.adminCampaignFragment}
`

export const copyCampaignMutation = gql`
  mutation copyCampaign($id: ID!) {
    copyCampaign(id: $id) {
      id
      promotion_code
    }
  }
`

// export const paypalAuthorizePayment = gql`
//   mutation paypalAuthorizePayment($amount: Int!) {
//     paypalAuthorizePayment(amount: $amount) {
//       state
//       token
//       paymentId
//       approvalUrl
//     }
//   }
// `

// export const paypalExecutePayment = gql`
//   mutation paypalExecutePayment($input: PaypalDataInput, $token: String!) {
//     paypalExecutePayment(input: $input, token: $token) {
//       state
//       token
//       paymentId
//       authorizationId
//     }
//   }
// `

// export const registerPaypalMutation = gql`
//   mutation($user_id:ID!, $paypal_id:String!){
//     registerPaypal(user_id: $user_id, paypal_id: $paypal_id) {
//       ...UserFragment
//     }
//   }
//   ${fragments.userFragment}
// `

// export const requestSignupValidation = gql`
//   mutation($email:String!){
//     requestSignupValidation(email: $email)
//   }
// `

// export const resendValidationEmail = gql`
//   mutation($userId:ID!){
//     resendValidationEmail(user_id:$userId)
//   }
// `

// export const requestPaymentToken = gql`
//   mutation($source:ID!) {
//     requestPaymentToken(source_id: $source) {
//       status
//       token
//       qr_code
//       valid_until
//       order_id
//     }
//   }
// `

export const submitFeedback = gql`
  mutation($vote: FeedbackVote, $text: String, $email: String, $order_id: ID) {
    submitFeedback(vote: $vote, text: $text, email: $email, order_id: $order_id)
  }
`

// export const addFavoriteStoreMutation = gql`
//   mutation($storeId: ID!) {
//     addFavoriteStore(store_id: $storeId) {
//       id
//       favorite_stores
//     }
//   }
// `

// export const removeFavoriteStoreMutation = gql`
//   mutation($storeId: ID!) {
//     removeFavoriteStore(store_id: $storeId) {
//       id
//       favorite_stores
//     }
//   }
// `

// export const acknowledgedMessageMutation = gql`
//   mutation($userId:ID!, $messageId: ID!) {
//     acknowledgedMessage(user_id: $userId, messageId: $messageId) {
//       id
//       success_campaign {
//         id
//       }
//     }
//   }
// `

export const addToBasket = gql`
  mutation addItemToBasket($id: ID, $item: BasketItemCreate!) {
    addItemToBasket(basket_id: $id, item: $item) {
      ...BasketFragment
    }
  }
  ${fragments.basketFragment}
`

export const removeFromBasket = gql`
  mutation($id: ID!, $item_id: ID!) {
    removeFromBasket(basket_id: $id, item_id: $item_id) {
      ...BasketFragment
    }
  }
  ${fragments.basketFragment}
`

export const updateBasketItem = gql`
  mutation($id: ID!, $item_id: ID!, $item: BasketItemUpdate!) {
    updateItem(basket_id: $id, item_id: $item_id, item: $item) {
      ...BasketFragment
    }
  }
  ${fragments.basketFragment}
`


export const createCategory = gql`
  mutation($new_category: CreateCategory!) {
    createCategory(item: $new_category) {
      ...CategoryFragment
    }
  }
  ${fragments.categoryFragment}
  ${fragments.productFragment}
`

export const deleteBasket = gql`
  mutation($id: ID!) {
    deleteBasket(basket_id: $id) {
      ...BasketFragment
    }
  }
  ${fragments.basketFragment}
`

export const removeProductFromCategory = gql`
  mutation($product_id: ID!, $category_id: ID!) {
    removeProductFromCategory(product_id: $product_id, category_id: $category_id)
  }
`

export const removeProductFromCategoryAndDeleteIt = gql`
  mutation($product_id: ID!, $category_id: ID!) {
    removeProductFromCategory(product_id: $product_id, category_id: $category_id)
    saveRemoveProduct(product_id: $product_id)
  }
`

export const updateProduct = gql`
  mutation($id: ID!, $product: ProductInput!) {
    updateProduct(id: $id, payload: $product) {
      ...ProductFragment
    }
  }
  ${fragments.productFragment}
`

export const ingestPlaceholderProduct = gql`
  mutation($brand_id: ID) {
    ingestProduct(product_id: "GENERATE_RND", 
      payload: { 
        brand_id: $brand_id,
        name: "🐇 Placeholder"
        price: 1000,
        teaser_text: "🐇 Placeholder",
        vat_rate: 70,
        type: PRODUCT,
        }) {
      id
    }
  }
`

export const ingestPlaceholderExtra = gql`
  mutation($brand_id: ID) {
    ingestProduct(product_id: "GENERATE_RND", 
      payload: { 
        brand_id: $brand_id,
        name: "🐇 Extra Placeholder"
        price: 1000,
        type: EXTRA,
        }) {
      id
    }
  }
`

export const requestUploadUrl = gql`
 mutation($filename: String, $filetype: String) {
  requestUploadUrl(filename: $filename, filetype: $filetype)
 }
`

export const removeCategoryFromStore = gql`
  mutation($category_id: ID!, $store_id: ID!) {
      removeCategoryFromStore(category_id: $category_id, store_id: $store_id) {
        ...StoreFragment
      }
  }
  ${fragments.storeFragment}
`

export const copyCategoriesMutation = gql`
  mutation($ids: [ID], $add_to_store: ID) {
      copyCategories(ids: $ids, add_to_store: $add_to_store)
  }
`

export const setAppMetadataMutation = gql`
  mutation($user_id: ID!, $json: String!){
    setAppMetadata(user_id: $user_id, json: $json) {
       id
    }
  }  
`

export const createCampaignMutation = gql`
  mutation($promotion_code: String!){
    createCampaign(promotion_code: $promotion_code) {
       id
       promotion_code
    }
  }  
`

export const updateStore = gql`
  mutation($id: ID!, $store: UpdateStore) {
    updateStore(id: $id, payload: $store) {
      ...StoreFragmentWithCategories
    }
  }
  ${fragments.storeFragmentWithCategories}
`

export const addCategoryToStore = gql`
  mutation($store_id: ID!, $category_id: ID!) {
    addCategoryToStore(store_id: $store_id, category_id: $category_id){
      ...StoreFragment
    }
  }
  ${fragments.storeFragment}
`

export const copyExtras = gql`
  mutation($product_id_from: ID!, $variant_id_to: ID!) {
    copyExtras(product_id_from: $product_id_from, variant_id_to: $variant_id_to) {
      ...ProductFragment
    }
  }
  ${fragments.productFragment}
`
export const copyVariant = gql`
  mutation($variant_id: ID!, $from_product_id: ID!, $to_product_id: ID!) {
    copyVariant(variant_id: $variant_id, from_product_id: $from_product_id, to_product_id: $to_product_id) {
      ...ProductFragment
    }
  }
  ${fragments.productFragment}
`

export const removeExtraGroupFromProduct = gql`
  mutation($product_id: ID!, $group_id: ID!) {
    removeExtraGroupFromProduct(product_id: $product_id, group_id: $group_id) {
      ...ProductFragment
    }
  }
  ${fragments.productFragment}
`
export const createProduct = gql`
  mutation($payload: ProductInput!) {
    createProduct(payload: $payload) {
      ...ProductFragment
    }
  }
  ${fragments.productFragment}
`


export const updateProductAsCopy = gql`
  mutation($id: ID!, $replace_in_category: ID!, $product: ProductInput!) {
    updateProductAsCopy(id: $id, replace_in_category: $replace_in_category, payload: $product){
      ...ProductFragment
    }
  }
  ${fragments.productFragment}
`

export const updateExtra = gql`
  mutation($product_id: ID!, $extra_id: ID!, $payload: ProductInput!) {
    updateExtra(product_id: $product_id, extra_id: $extra_id, item: $payload) {
      ...ProductFragment
    }
  }
  ${fragments.productFragment}
`

export const updateVariant = gql`
  mutation($variant_id: ID!, $item: VariantInput!) {
    updateVariant(variant_id: $variant_id, item: $item) {
      ...VariantProductFragment
    }
  }
  ${fragments.variantProductFragment}
`

export const updateCategory = gql`
  mutation($id: ID!, $item: UpdateCategory!) {
    updateCategory(id: $id, item: $item) {
      id
      name
      icon
      icon_cyan
      placeholder
      products {
        ...ProductFragment
      }
    }
  }
  ${fragments.productFragment}
`

export const updateExtraGroup = gql`
  mutation($product_id: ID!, $group_id: ID!, $item: ExtraGroupUpdate!) {
    updateExtraGroup(product_id: $product_id, group_id: $group_id, item: $item) {
      product {
        ...ProductFragment
      }
      group_id
    }
  }
  ${fragments.productFragment}
`

export const addExtraToGroup = gql`
  mutation($product_id: ID!, $group_id: ID!, $extra_id: ID!) {
    addExtraToGroup(product_id: $product_id, group_id: $group_id, extra_id: $extra_id) {
      group_id
      product {
        ...ProductFragment
      }
    }
  }
  ${fragments.productFragment}
`

export const addExtrasToGroup = gql`
  mutation($product_id: ID!, $group_id: ID!, $extra_ids: [ID]) {
    addExtrasToGroup(product_id: $product_id, group_id: $group_id, extra_ids: $extra_ids) {      
      ...ProductFragment
    }
  }
  ${fragments.productFragment}
`

export const addProductsToCategory = gql`
  mutation($category_id: ID!, $product_ids: [ID]) {
    addProductsToCategory(category_id: $category_id, product_ids: $product_ids){
      ...CategoryFragment
    }
  }
  ${fragments.categoryFragment}
  ${fragments.productFragment}
`

export const removeExtraFromGroup = gql`
  mutation($product_id: ID!, $group_id: ID!, $extra_id: ID!) {
   removeExtraFromGroup(product_id: $product_id, group_id: $group_id, extra_id: $extra_id) {
        ...ProductFragment
   }
  }
  ${fragments.productFragment}
`

export const addExtraGroup = gql`
  mutation($product_id: ID!, $group_id: ID, $item: ExtraGroupCreate) {
   addExtraGroup(product_id: $product_id, group_id: $group_id, item: $item){
        group_id
        product {
          ...ProductFragment
        }
   }
  }
  ${fragments.productFragment}
`
