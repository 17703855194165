import React from 'react'
import styled from 'styled-components'
import {
  FiArrowLeft,
  FiMapPin,
  FiClock,
  FiChevronUp,
  FiChevronDown,
  FiCheck,
  FiPlus,
  FiShoppingCart,
  FiTrash,
  FiInfo,
  FiCreditCard,
  FiArrowRight,
  FiLock,
  FiEdit2,
  FiClipboard,
} from 'react-icons/fi'

const iconMap = {
  // allergens: @FIXME: key should match graphql allergy value
  corn: require('../../images/allergens/corn.png'),
  crustean: require('../../images/allergens/crustean.png'),
  dairy: require('../../images/allergens/dairy.png'),
  egg: require('../../images/allergens/egg.png'),
  fish: require('../../images/allergens/fish.png'),
  gluten: require('../../images/allergens/gluten.png'),
  KR: require('../../images/allergens/KR.png'),
  lactose: require('../../images/allergens/lactose.png'),
  LU: require('../../images/allergens/LU.png'),
  mustard: require('../../images/allergens/mustard.png'),
  no_trans_fat: require('../../images/allergens/no trans fat.png'),
  nut: require('../../images/allergens/nut.png'),
  peanut: require('../../images/allergens/peanut.png'),
  SE: require('../../images/allergens/SE.png'),
  SL: require('../../images/allergens/SL.png'),
  SO: require('../../images/allergens/SO.png'),
  sugar: require('../../images/allergens/sugar.png'),
  vegan: require('../../images/allergens/vegan.png'),
  WE: require('../../images/allergens/WE.png'),
  // feather icons
  arrow_left: <FiArrowLeft />,
  arrow_right: <FiArrowRight />,
  clock: <FiClock />,
  mapPin: <FiMapPin />,
  angle_up: <FiChevronUp />,
  angle_down: <FiChevronDown />,
  check: <FiCheck />,
  plus: <FiPlus />,
  cart: <FiShoppingCart />,
  trash: <FiTrash />,
  info: <FiInfo />,
  card: <FiCreditCard />,
  lock: <FiLock />,
  edit: <FiEdit2 />,
  clipboard: <FiClipboard />,
}

const IconContainer = styled.div`
  width: min-content;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ color }) => color ? `color: ${color};` : ''};
`

function Icon({ name, ...props }) {
  return <IconContainer {...props}>{iconMap[name]}</IconContainer> || null
}

export default Icon
