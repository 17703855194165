import styled from 'styled-components'
import { HeaderSmall } from '../../components/Text'
import Color from '../../utils/color'

export const ListContainer = styled.div`
  padding: 2rem;
`

export const SearchBox = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 2rem;
  & > div {
    flex: 1;
    margin-right: 1rem;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${Color.MudGrey};
  padding: 2rem;
  background: ${props => props.highlight ? Color.Berry : Color.CoolGrey};
  cursor: pointer;
`
export const Name = styled(HeaderSmall)`
  flex: 1;
  color: ${props => props.highlight ? Color.White : Color.DarkGreen};
`

export const Info = styled(HeaderSmall)`
  text-align: center;
  background: ${props => props.highlight ? Color.Berry : Color.CoolGrey};
  color: ${props => props.highlight ? Color.White : Color.DarkGreen};
`
