import { isBrowser } from './window'

const config = {
  production: {
    SENTRY_ENDPOINT: 'https://937924b432154737854ac81825b36cf8@sentry.io/1779871',
    APOLLO_ENDPOINT: 'https://colugo.pickpack.de',
    STRIPE_PUBLIC_KEY: 'pk_live_HVwD5n18laakP9IAzAdAqmry',
    AUTH0_DOMAIN: 'b2g.eu.auth0.com',
    AUTH0_CLIENTID: 'AfgHBSGgkgY6NnD6zvw6Yi-3HbdNCHkt',
    UPLOAD_URL: 'https://colugo.pickpack.de/requestUploadURL',
    GA_ID: 'UA-154163118-1',
    STAGE: 'PROD',
    // GOOGLE_MAP_KEY: 'AIzaSyDm0ACk0sMZsL9MGBvITQK2c-AvrZQHZo8',
    // GOOGLE_MAP_URL: 'https://maps.googleapis.com/maps/api/directions/json?',
    // APPLE_PAY_MERCHANT_ID: 'merchant.de.pickpack',
    // GOOGLE_PAY_MERCHANT_ID: '15192999776129618742',
  },
  development: {
    SENTRY_ENDPOINT: 'https://937924b432154737854ac81825b36cf8@sentry.io/1779871',
    APOLLO_ENDPOINT: 'https://colugo-dev.pickpack.de',
    STRIPE_PUBLIC_KEY: 'pk_test_R7QuectOQ5mvpCYKYZm1EBta',
    AUTH0_DOMAIN: 'b2g.eu.auth0.com',
    AUTH0_CLIENTID: 'AfgHBSGgkgY6NnD6zvw6Yi-3HbdNCHkt',
    UPLOAD_URL: 'https://colugo-dev.pickpack.de/requestUploadURL',
    GA_ID: 'UA-154163118-1',
    STAGE: 'DEV',
    // GOOGLE_MAP_KEY: 'AIzaSyDm0ACk0sMZsL9MGBvITQK2c-AvrZQHZo8',
    // GOOGLE_MAP_URL: 'https://maps.googleapis.com/maps/api/directions/json?',
    // APPLE_PAY_MERCHANT_ID: 'merchant.de.pickpack',
    // GOOGLE_PAY_MERCHANT_ID: '15192999776129618742',
  },
  localDevelopment: {
    APOLLO_ENDPOINT: 'http://localhost:3000',
    STRIPE_PUBLIC_KEY: 'pk_test_R7QuectOQ5mvpCYKYZm1EBta',
    AUTH0_DOMAIN: 'b2g.eu.auth0.com',
    AUTH0_CLIENTID: 'AfgHBSGgkgY6NnD6zvw6Yi-3HbdNCHkt',
    UPLOAD_URL: 'https://colugo-dev.pickpack.de/new/requestUploadURL',
    GA_ID: 'UA-154163118-1',
    STAGE: 'DEV',
    // GOOGLE_MAP_KEY: 'AIzaSyDm0ACk0sMZsL9MGBvITQK2c-AvrZQHZo8',
    // GOOGLE_MAP_URL: 'https://maps.googleapis.com/maps/api/directions/json?',
    // APPLE_PAY_MERCHANT_ID: 'merchant.de.pickpack',
    // GOOGLE_PAY_MERCHANT_ID: '15192999776129618742',
  },
}

export const AppConfig = isBrowser && ['fbb.pickpack.de', 'web.pixelport.now.sh'].includes(window.location.host) ? config.production : config.development
