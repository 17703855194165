import styled from 'styled-components'
import LazyImage from '../../components/LazyImage'
import { FLEX_CENTER_CENTER } from '../../utils/styles'
import IconText from '../../components/IconText'

export const StoreImageContainer = styled.div`
  width: 100%;
  height: calc(200px + constant(safe-area-inset-top));
  height: calc(200px + env(safe-area-inset-top));
  background: #fff;
  position: relative;
`

export const StoreImage = styled(LazyImage)`
  object-fit: cover;
`

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`

export const StoreName = styled.div`
  font-size: 1.5rem;
  color: #fff;
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  line-height: 1.3;
`

export const StoreLocation = styled(IconText)`
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 0.5rem 1rem;
`

export const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 100%;
`

export const BottomButtonsContainer = styled.div`
  padding-bottom: 1rem;
  ${FLEX_CENTER_CENTER};
`

export const FooterButtonText = styled.div`
  ${FLEX_CENTER_CENTER};
`
