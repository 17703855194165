import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'
import { AppConfig } from '../utils/config'

export const StripeContext = createContext()

function StripeContextProvider({ children, location: { pathname } }) {
  const [stripe, setStripe] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    if (loaded && !error && window.Stripe && !stripe) {
      setStripe(window.Stripe(AppConfig.STRIPE_PUBLIC_KEY))
    }
  }, [loaded])

  useEffect(() => {
    if (pathname.includes('pay')) {
      const id = 'stripe-js'
      // check if script is already loaded
      if (!document.getElementById(id)) {
        const script = document.createElement('script')
        script.src = 'https://js.stripe.com/v3/'
        script.async = true
        script.id = id

        script.addEventListener('load', () => setLoaded(true), { once: true })
        script.addEventListener('error', () => {
          if (script) script.remove()
          setError('Load Error Stripe')
        }, { once: true })

        document.body.appendChild(script)
      }
    }
  }, [pathname])

  return (
    <StripeProvider stripe={stripe}>
      {children}
    </StripeProvider>
  )
}

StripeContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
}

export default withRouter(StripeContextProvider)
