import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import zIndex from '../../utils/zIndex'

const Banner = styled.div`
  background: ${props => props.theme.color.primary};
  color: #fff;
  padding: 0.7rem;
  text-align: center;
  z-index: ${zIndex.banner};
`

function ActiveOrderBanner({ onClick, pickup_time }) {
  return (
    <Banner onClick={onClick}>
      <FormattedMessage
        id="components.TimePicker.ResolveButton"
        values={{ time: moment(pickup_time).format('HH:mm') }}
      />
    </Banner>
  )
}

ActiveOrderBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
  pickup_time: PropTypes.string.isRequired,
}

export default ActiveOrderBanner
