import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LoadingSpinner from '../LoadingSpinner'
import Color from '../../utils/color'

const BasicButton = styled.button`
  background: ${({ color, theme }) => color || theme.color.primary};
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:disabled {
    background: ${Color.MudGrey};
  }
  ${props => props.small && `
    font-size: 12px;
    padding: 5px 10px;
  `}
  ${props => props.inline && `
    display: inline-block;
  `}
`

const Container = styled.div`
  display: flex;
  white-space: pre;
`

function Button({
  children, disabled, loading, small, ...rest
}) {

  return (
    <BasicButton {...rest} type="button" small={small} disabled={disabled || loading}>
      {loading && <LoadingSpinner size={1} sizeUnit="rem" />}
      <Container>
        {children}
      </Container>
    </BasicButton>
  )
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  small: PropTypes.bool,
}

Button.defaultProps = {
  disabled: false,
  loading: false,
  small: false,
}

export default Button
