import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../Button'

const BaseFooterButton = styled(Button)`
  flex: 1;
`

function FooterButton({ children, ...rest }) {
  return <BaseFooterButton round {...rest}>{children}</BaseFooterButton>
}

FooterButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
}

export default FooterButton
