import React, {
  useState, useEffect, useMemo, createContext,
} from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import MESSAGES_DE from '../i18n/de.json'
import MESSAGES_EN from '../i18n/en.json'
import { setItem } from '../utils/localStorage'
import { getGlobalContext } from '../utils/window'

// // https://github.com/formatjs/react-intl/blob/7a6068634c24f7e02ed5c9e3e78bd20465c9f846/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
// if you want to disable warning in tests
// if (!Intl.PluralRules) {
//   require('@formatjs/intl-pluralrules/polyfill')
//   require('@formatjs/intl-pluralrules/dist/locale-data/de')
// }
// if (!Intl.RelativeTimeFormat) {
//   require('@formatjs/intl-relativetimeformat/polyfill')
//   require('@formatjs/intl-relativetimeformat/dist/locale-data/de')
// }

const locales = {
  de: 'de',
  en: 'en',
}

const messages = {
  de: MESSAGES_DE,
  en: MESSAGES_EN,
}

const defaultLocale = locales.de

export const LanguageContext = createContext()

function LanguageContextProvider({ children, match: { params } }) {
  const initialLocale = useMemo(() => {
    if (params.locale) {
      return locales[params.locale]
    }
    return defaultLocale
  }, [params.locale])

  getGlobalContext().pickpackLocale = params.locale

  const [locale, setLocale] = useState(initialLocale)

  useEffect(() => {
    const langLocale = locale || defaultLocale
    setItem('langLocale', langLocale)
  }, [locale])

  const langLocale = locale || defaultLocale

  return (
    <IntlProvider locale={langLocale} messages={messages[langLocale]}>
      <LanguageContext.Provider value={{ locale, setLocale }}>
        {children}
      </LanguageContext.Provider>
    </IntlProvider>
  )
}

LanguageContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
  match: PropTypes.object.isRequired,
}

export default withRouter(LanguageContextProvider)
