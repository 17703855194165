import gql from 'graphql-tag'
import * as fragments from './fragments'

export const basketQuery = gql`
  query basket($id: ID!) {
    basket(id: $id) {
      ...BasketFragment
    }
  }
  ${fragments.basketFragment}
`

export const campaignSearchQuery = gql`
  query campaignSearch($search_term: String!){
    campaignSearch(search_term: $search_term){
      id
      offer_name
      promotion_code
    }
  }
`

export const storeBasket = gql`
  query storeBasket($store_id: ID!, $campaigns: OverwriteCampaign) {
    storeBasket(store_id: $store_id, campaigns: $campaigns) {
      ...BasketFragment
    }
  }
  ${fragments.basketFragment}
`

export const searchUsers = gql`
  query searchUsers($search_term: String) {
    searchUsers(search_term: $search_term) {
      id
      name
      email
    }
  }
`

export const sessionQuery = gql`
  {
    session @client {
      user {
        ...UserFragment
      }
      store {
        ...StoreFragment
      }
      basket {
        ...BasketFragment
      }
      order {
        ...OrderFragment
      }
      pickup {
        time
        isInstantOrder
      }
    }
  }
  ${fragments.userFragment}
  ${fragments.storeFragment}
  ${fragments.basketFragment}
  ${fragments.orderFragment}
`

// export const brandStores = gql`
//   query($brand_id: ID!, $geokey: String) {
//     brandStores(brand_id: $brand_id, geokey: $geokey) {
//       id
//       name
//       location_name
//       geo_hash
//       online
//       is_open
//       default_extras
//       has_inputfield
//       minimum_extras
//       maximum_extras
//       product_tags
//       opening_hours {
//         day
//         time
//       }
//       brand {
//         id
//         logo
//       }
//       distance {
//         distance
//         unit
//         raw
//       }
//     }
//   }
// `

export const adminCampaignQuery = gql`
query campaign($id: ID!){
  campaign(campaign_id: $id) {
    ...AdminCampaignFragment
  }
}
${fragments.adminCampaignFragment}
`

export const adminUserOrderQuery = gql`
  query order($user_id: ID){
    orders(user_id: $user_id){
        id
        user_id
        pickup_time
        status
        total
        readable_id
        code_text
        store_total
        store {
          id
          name
        }
    }
  }
`

export const adminUserQuery = gql`
query user($id: ID!) {
  user(id: $id) {
    id
    name
    given_name
    family_name
    fraud_check
    email
    email_verified
    nickname
    role
    phone_number
    phone_number_verified
    used_brand_ids
    favorite_stores
    app_metadata
    active_campaigns {
      id
      quantity_max
      promotion_code
      start_date
      end_date
      terms
      discount_percent
      user_quantity
      offer_name
      referral_name
    }
    payment_details {
      paypal_id
      stripe_id
      default_source {
        id
        brand
        last4
        object
        fingerprint
        name
        type
      }
      sources {
        id
        brand
        last4
        object
        fingerprint
        name
        type
      }
    }
    accepted_agb
    accept_agb
    accept_privacy
    accept_push
    push_id
    accept_newsletter
    picture
    referral_code
    success_campaign {
      id
      offer_name
      referral_name
      show_success
    }
  }
}

`

export const userQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`

export const getUser = gql`
  query ($forceRefresh: Boolean, $sessionDeviceID: ID){
    getUser(forceRefresh: $forceRefresh, session_device_id: $sessionDeviceID) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`

// export const productQuery = gql`
//   query product ($id: ID!) {
//     product(id: $id) {
//       ...ProductFragment
//     }
//   }
//   ${fragments.productFragment}
// `

// export const checkPromotionCode = gql`
//   query checkPromotionCode ($code: String) {
//     checkPromotionCode(promotion_code: $code) {
//       ...CampaignFragment
//     }
//   }
//   ${fragments.campaignFragment}
// `

// export const storeFront = gql`
//   query storeFront($id: ID!) {
//     storefront(store_id: $id , filter: "ONLINE") {
//       store {
//         ...StoreFragment
//       }
//       brand {
//         id
//         name
//         short_name
//         address
//         contact
//         logo
//       }
//       products {
//         categories {
//           name
//           icon
//           products {
//             ...ProductFragment
//             in_stock
//           }
//         }
//       }
//     }
//   }
//   ${fragments.productFragment}
//   ${fragments.storeFragment}
// `

export const productCategories = gql`
  query ($id: ID!, $filter: ProductFilter) {
    productCategories(store_id: $id, filter: $filter) {
      id
      name
      icon
      products {
        ...ProductFragment
        in_stock
      }
    }
  }
  ${fragments.productFragment}
`
export const brandCategoryProductsByStore = gql`
  query($store_id: ID!) {
      brandCategoryProductsByStore(store_id: $store_id) {
        store_id
        categories {
            id
            name
            product_ids
            products {
              id
              name
              extra_groups {
                extra_group_id
                name
                items {
                  extra_id
                  name
                  price
                }
              }
              variants {
                id
                variant_id
                extra_groups {
                  extra_group_id
                  name
                  items {
                    extra_id
                    name
                    price
                  }
                }
              }
            }
        }
      }
    }
`

export const storeQuery = gql`
  query store($id: ID!) {
    store(id: $id) {
      ...StoreFragment,
    }
  }
  ${fragments.storeFragment}
`

export const adminStoreQuery = gql`
  query store($id: ID!) {
    store(id: $id) {
      ...StoreFragmentWithCategories,
    }
  }
  ${fragments.storeFragmentWithCategories}
`

export const storeListQuery = gql`
   query stores($geokey: String) {
     stores(geokey: $geokey) {
       ...StoreFragment,
     }
   }
   ${fragments.storeFragment}
 `

// export const nearestFeaturedProducts = gql`
//   query nearestFeaturedProducts($geokey: String!, $proximity: Int!) {
//     nearestFeaturedProducts(geokey: $geokey, proximity: $proximity) {
//       ...ProductFragment
//       in_stock
//       distance
//       store {
//         ...StoreFragment
//       }
//     }
//   }
//   ${fragments.productFragment}
//   ${fragments.storeFragment}
// `

export const activeOrderQuery = gql`
  query order($id: ID!) {
    order(id: $id) {
      ...OrderFragment
    }
  }
  ${fragments.orderFragment}
`

export const singleOrderQuery = gql`
  query order($order_id: ID!) {
    order(id: $order_id) {
      id
      readable_id
      store_id
      user_id
      basket_id
      transaction_id
      payment_method
      code_text
      item_count
      total
      status_history {
        status
        updated_at
      }
      vat_total {
        vat_rate
        vat_amount
        total_amount
      }
      store_total
      terminal_total
      store_vat_total {
        vat_rate
        vat_amount
        total_amount
      }
      platform_fee
      status
      stripe_charge_id
      pickup_time
      type
      pickedup_time
      created_at
      updated_at
      items {
        id
        name
        price
        quantity
        product_id
        variant_id
        extras {
          extra_id
          name
          price
        }
      }
      campaign {
        id
        promotion_code
      }
      platform_fee_campaign_id
      cutlery
      eat_in
      bell_status
      is_test_order
      store_discount_amount
      user {
        id
        email
        given_name
        family_name
        name
      }
      store {
        name
      }
    }
  }
`


export const ordersQuery = gql`
  query orders($store_id: ID!, $timespan_from: String!){
    orders(store_id: $store_id, timespan: { from: $timespan_from }, sort_by: DESC){
        id
        user_id
        pickup_time
        status
        total
        readable_id
        code_text
        store_total
        user{
          id
          email
          given_name
          family_name
          name
        }
        store{
          name
        }
    }
  }
`

// export const orderOverviewQuery = gql`
//   query orders($user_id: ID!) {
//     orders( user_id: $user_id, sort_by: DESC) {
//       ...OrderFragment
//     }
//   }
//   ${fragments.orderFragment}
// `

// export const nearestStores = gql`
//   query nearestStores( $geokey: String!, $proximity: Int!, $filter: StoreFilter ) {
//     nearestStores(geokey: $geokey, proximity: $proximity, filter: $filter) {
//       ...StoreFragment
//     }
//   }
//   ${fragments.storeFragment}
// `

// export const nearestGroupedStores = gql`
//   query nearestGroupedStores($geokey: String!, $proximity: Int!, $filter: StoreFilter) {
//     nearestGroupedStores(geokey: $geokey, proximity: $proximity, filter: $filter) {
//       store {
//         ...StoreCardFragment
//       }
//       close_stores {
//         ...StoreCardFragment
//       }
//     }
//   }
//   ${fragments.storeCardFragment}
// `

// export const locationsQuery = gql`
//   query locations {
//     locations {
//       ...LocationFragment
//     }
//   }
//   ${fragments.locationFragment}
// `

// export const locationStores = gql`
//   query locationStores($location_id: ID!, $geokey: String, $filter: StoreFilter) {
//     locationStores(location_id: $location_id, geokey: $geokey, filter: $filter) {
//       stores {
//         ...StoreFragment
//       }
//       near_stores {
//         ...StoreFragment
//       }
//     }
//   }
//   ${fragments.storeFragment}
// `

// export const locationGroupedStores = gql`
//   query locationGroupedStores($location_id: ID!, $geokey: String, $filter: StoreFilter) {
//     locationGroupedStores(location_id: $location_id, geokey: $geokey, filter: $filter) {
//       stores {
//         store {
//           ...StoreCardFragment
//         }
//         close_stores {
//           ...StoreCardFragment
//         }
//       }
//       near_stores {
//         store {
//           ...StoreCardFragment
//         }
//         close_stores {
//           ...StoreCardFragment
//         }
//       }
//     }
//   }
//   ${fragments.storeCardFragment}
// `

// export const checkOTT = gql`
//   query checkOTTPayment($token: ID!) {
//     checkPaymentToken(token: $token) {
//       status
//       order_id
//     }
//   }`

// export const checkEmail = gql`
//   query checkEmail( $email: String! ) {
//     checkEmail(email: $email)
//   }
// `

// export const clearCache = gql`
//   query { brands { id } }
// `
